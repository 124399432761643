.on-chain-order-total-value {
  width: 73%;
}

.ui-on-chain-loading-container {
  width: 430px;
  top: 280px;
  bottom: 30px;
  position: relative;
  right: 13%;
}

#moon-ui-on-chain-ui input:disabled {
  background-color: #ffffff;
}

#moon-ui-on-chain {
}

#moon-ui-on-chain-ui .header,
#moon-ui-on-chain-ui .body {
  display: inline-block;
}

#moon-ui-on-chain-ui .header {
  width: 100%;
}

@media (max-width: 1080px) {
  .mobile-view {
    display: initial;
  }
  #moon-ui-on-chain {
    margin: 0 20px;
  }
  #moon-ui-on-chain-ui .header {
    padding-right: 0;
  }
  .header {
    margin-top: 20px;
    order: 1;
    max-width: 500px;
    place-self: center;
  }
  .ui-on-chain-loading-container {
    right: 0;
  }
  .ui-on-chain-loading-container-mobile{
    right: 13%;
  }
}
