.product-content #product-video iframe {
    border-radius: 4px;
    border: 1px solid #d8d8d8;
    max-width: 100px;
    height: auto;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.how-to-section {
    opacity: 0.5;
    cursor: pointer;
}

#scroller{
    top: 0;
    left: 50%;
    width: 1px;
    height: 25%;
    position: absolute;
    border-left: solid 2px #0c1436;
}

/* iPhone X - Portrait */
@media only screen
and (min-device-width : 375px)
and (max-device-width : 812px)
and (-webkit-device-pixel-ratio : 3)
and (orientation : portrait) {
    .product-content #product-video {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0 !important;
        padding-bottom: 20px !important;
    }

    .one-two-three {
        display: block;
    }

    .vertical-bar{
        display: none;
    }

    .how-it-works-vid {
        display: none;
    }
}

@media only screen
and (min-device-width : 812px){
    .one-two-three {
        display: none;
    }
}



/* iPhone x - Landscape */
@media only screen
and (min-device-width : 375px)
and (max-device-width : 812px)
and (-webkit-device-pixel-ratio : 3)
and (orientation : landscape) {

    .product-content.step {
        padding-top: 30px !important;
        padding-bottom: 30px !important;

    }

    .one-two-three {
        display: block;
    }

    .vertical-bar{
        display: none;
    }

    .product-content.step .product-heading {
        max-width: unset !important;

    }
    .product-content.step .product-heading h2 {
        font-size: 2rem;
        text-align: center !important;
    }

    .product-content.step .product-heading p {
        text-align: center;
    }
}

/* iPad 3 & 4 */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (-webkit-min-device-pixel-ratio: 2) {

    .product-content.step {
        padding-top: 30px !important;
        padding-bottom: 30px !important;

    }


    .product-content.step .product-heading {
        max-width: unset !important;

    }
    .product-content.step .product-heading h2 {
        font-size: 2rem;
        text-align: center !important;
    }

    .product-content.step .product-heading p {
        text-align: center;
    }

}