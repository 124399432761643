.apply-moon-credit-checkbox{
    position: relative;
    cursor: pointer;
    top: 2px;
}

.apply-rewards-credit-checkbox{
    position: relative;
    cursor: pointer;
    top: 2px;
}

.apply-moon-credit-amount{
    cursor: pointer;
}

.apply-rewards-credit-amount{
    cursor: pointer;
}