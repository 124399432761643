/* iPhone x - Landscape */
@media only screen
and (min-device-width : 375px)
and (max-device-width : 812px)
and (-webkit-device-pixel-ratio : 3)
and (orientation : landscape) {
    .product-content {
        text-align: center;
    }
}

@media only screen
and (max-device-width : 812px){
    .product-content-seen-in {
        margin-top: -50px;
    }
}