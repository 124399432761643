.activity-placeholder {
    height: 46px;
    font-style: italic;
    color: gray;
}

.activity-section-loading-placeholder {
    width: 500px;
}

.card-placeholder {
    width: 315px;
    height: 206px;
    background-color: #0666d0;
    font-size: 120px;
    font-weight: 500;
    color: white;
    border-radius: 17px;
    padding: 10px;
    box-shadow: 0px 5px 7px 4px #a9a9a9;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: active-loader 0.8s;
}

.card-placeholder-disabled {
    width: 315px;
    height: 206px;
    background-color: #919597;
    font-size: 120px;
    font-weight: 500;
    color: white;
    border-radius: 17px;
    padding: 10px;
    box-shadow: 0px 5px 7px 4px #a9a9a9;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: active-loader 0.8s;
}

@media (max-width: 1093px){
    .card-placeholder{
        animation: none;
    }
    .card-placeholder-disabled{
        animation: none;
    }
}

.card-placeholder:hover {
    transform: scale(1.03);
    cursor: pointer;
}

.card-placeholder-disabled{
    cursor: not-allowed;
}

.card-placeholder p{
    margin: 0px;
}

.card-placeholder-disabled p{
    margin: 0px;
}

.card-placeholder-container {
    position: relative;
    width: 330px;
    height: 216px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.centered {
    text-align: -webkit-center;
}

.activity-info-link{
    font-size: 14px;
    width: max-content;
}

.user-ban-message {
    display: block;
    text-align: center;
    margin-top: 50px;
    color: #7F7F7F;
    line-height: 1.7;
    font-size: 17px;
    padding-top: 20px;
    padding-left: 100px;
    padding-right: 100px;
}

.user-ban-message-mobile {
    display: block;
    text-align: center;
    margin-top: 50px;
    color: #7F7F7F;
    line-height: 1.7;
    font-size: 17px;
    padding-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
}

.user-ban-icon {
    padding-top: 30px;
    margin: 0 auto;
    display: block;
}