.rightTab{
    width: 100%;
    border: none;
    background-color: white;
    box-shadow: 0 4px 2px -1px #dbdbdb;
    font-size: 20px;
    font-weight: bold;
    padding: 15px;
}

.leftTab{
    width: 100%;
    border: none;
    background-color: white;
    box-shadow: 0 4px 2px -1px #dbdbdb;
    font-size: 20px;
    font-weight: bold;
    padding: 15px;
}

.rightTab:hover {
    cursor:pointer;
    background-color: #f6f6f7;
}

.leftTab:hover {
    cursor:pointer;
    background-color: #f6f6f7;
}

