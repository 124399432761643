.request-refund-p{
    margin: 0;
    font-size: 14px;
}
.max-btn{
    padding: 0 8px;
    box-shadow: none;
    border-radius: 3px;
    color: white;
    background: black;
    border: 2px solid #2E2F33;
    font-size: 15px;
    cursor: pointer;
    font-family: Quicksand;
    font-weight: 600 !important;
    letter-spacing: 0.5px;
    height: 30px;
    margin-left: 8px;
}
.request-refund-container{
    text-align: left;
    /* top: auto; */
    position: relative;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin: 0;
    width: 620px;
    background-color: white;
    padding: 30px 50px;
    justify-content: center;
    height: 430px;
    border-radius: 0.25rem;
    font-family: "Open Sans", sans-serif;
}

.flex{
    display: flex;
}

.request-refund-title {
    text-align: left;
}

.request-refund-input{
    height: 30px;
    padding: 6px;
}
.request-refund-input:focus{
    outline: none;
}
.request-refund-input::-webkit-outer-spin-button,
.request-refund-input::-webkit-inner-spin-button,
.request-refund-verification-code-input::-webkit-outer-spin-button,
.request-refund-verification-code-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.request-refund-input[type=number], 
.request-refund-verification-code-input[type=number]{
    -moz-appearance: textfield;
}

.request-refund-input-title{
    font-size: 16px;
    font-weight: 600;
    margin-top: 2px;
}

.refund-request-continue-btn{
    float: right;
    width: 120px;
    margin-top: 27px;
    position: absolute;
    bottom: 30px;
    right: 50px;
}
.currency-input{
    border: 1px solid rgb(118, 118, 118);
    border-radius: 0.25rem;
    padding-left: 6px;
}

.refund-request-back-btn{
    float: right;
    width: 120px;
    margin-top: 27px;
    position: absolute;
    bottom: 30px;
    right: 180px;
    border-color: #007bff;
    color: #007bff;
}

.refund-request-cancel-btn{
    float: right;
    width: fit-content;
    margin-top: 27px;
    position: absolute;
    bottom: 30px;
    right: 180px;
    text-decoration: underline;
    color: #606060;
    margin: 6px 10px;
    padding: 0;
}

.request-refund-verification-code-input{
    height: 45px;
    text-align: center;
    font-size: 21px;
    width: 170px;
    letter-spacing: 3px;
    padding: 6px;
}

.request-refund-verification-code-input::placeholder, .refund-amount::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #AAAAAA;
  }
  
.request-refund-verification-code-input:-ms-input-placeholder, .refund-amount:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #AAAAAA;
  }
  
.request-refund-verification-code-input::-ms-input-placeholder, .refund-amount::-ms-input-placeholder { /* Microsoft Edge */
    color: #AAAAAA;
}

.resend-verification-code-btn{
    color: #AAAAAA;
    font-size: 13px;
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
    width: fit-content;
    font-family: Quicksand !important;
    position: relative;
    top: 5px;
}

.resend-verification-code-success{
    color: green;
    font-size: 13px;
    font-weight: 600;
    width: fit-content;
    font-family: Quicksand !important;
    position: relative;
    top: 5px;
}

.refund-request-cancel-btn:hover{
    text-decoration: underline;
}

.refund-request-back-btn:hover{
    border-color: #007bff;
    color: #007bff;
}

.refund-request-error-text{
    font-size: 13px;
    color: red;
}

.moon-credit-available{
    font-size: 13px;
}

.refund-amount-input-container{
    display: flex;
}

.address-input{
    width: 380px;
}

.refund-amount-value{
    font-size: 16px;
    font-weight: 400;
}

@media (max-width: 970px) {
    .request-refund-container{
        position: initial;
        transform: unset;
        -webkit-transform: unset;
        margin: 0;
        width: 100%;
        padding: 0;
        height: auto;
    }
    .create-card-title {
        display: none;
    }
    .desktop-refund-close-button {
        display: none;
    }
    .request-refund-title{
        margin-top: 15px;
        font-weight: bold;
        margin-bottom: 15px;
    }
    .request-refund-input-title{
        text-align: center;
        font-size: 26px;
    }
    .moon-credit-available{
        font-size: 16px;
        text-align: center;
        margin-bottom: 20px;
    }
    .refund-amount-input-container{
        justify-content: center;
    }
    .address-input{
        margin-top: 20px;
    }
    .address-input-container{
        text-align: center;
    }
    .refund-request-continue-btn-container{
        text-align: center;
    }
    .refund-request-continue-btn{
        float: none;
        width: 380px;
        margin-top: 30px;
        position: relative;
        bottom: 0;
        right: 0;
    }
    .request-refund-input{
        height: 35px;
        font-size: 18px;
    }
    .max-btn{
        height: 35px;
    }
    .refund-amount-input-container{
        font-size: 18px;
    }
    .refund-request-error-text{
        font-size: 14px;
        position: initial;
        text-align: center;
        margin-top: 5px;
    }  
    .confirm-refund-amount{
        display: grid;
    }
    .confirm-refund-address{
        display: grid;
    }
    .refund-amount-value{
        font-size: 21px;
        margin-top: 10px;
    }
    .resend-verification-code-btn{
        font-size: 14px;
    }
    .refund-request-cancel-btn{
        float: none;
        width: 100%;
        position: relative;
        bottom: 0;
        right: 0;
        margin: 0;
        margin-top: 10px;
    }
}

@media (max-width: 500px){
    .address-input{
        width: 100%;
    }
    .refund-request-continue-btn{
        width: 100%;
    }
}