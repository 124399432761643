.usdc-payment-right-tab {
    width: 50%;
    right: 0px;
    background-color: white;
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
    border-radius: 0 1rem 1rem 0;
  }
  
  .usdc-payment-left-tab {
    width: 50%;
    left: 0px;
    background-color: white;
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
    border-radius: 1rem 0 0 1rem;
  }
  
  .usdc-payment-right-tab:hover {
    cursor: pointer;
    background-color: #f6f6f7;
  }
  
  .usdc-payment-left-tab:hover {
    cursor: pointer;
    background-color: #f6f6f7;
  }
  
  .usdc-payment-tabs-desktop {
    display: flex;
  }
  
  .usdc-payment-tabs-mobile {
    display: none;
  }

  .payment-title-desktop {
    font-size: 30px;
    font-weight: bold;
  }

  .payment-title-mobile {
    display: none;
  }
  
  @media (max-width: 1100px) {
    .usdc-payment-tabs-desktop {
      display: none;
    }
    .usdc-payment-tabs-mobile {
      display: flex;
      padding-left: 10px;
      padding-right: 10px;
    }
    .payment-title-desktop {
      display: none;
    }
    .payment-title-mobile {
      font-size: 30px;
      font-weight: bold;
      display: initial;
    }
  }
  