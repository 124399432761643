.drawer-close-icon {
    position:relative;
    width: 100%;
    text-align:right;
    margin-top: 10px;
    padding-right: 30px;
    color: rgba(0, 0, 0, 0.62);
}

.drawer-icon-spacer {
    width: 7%;
}

.drawer-item {
    width: 250px;
    height: 50px;
    text-align: center;
    font-family: monospace;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.62);
    padding-left: 10%;
    display: flex;
    align-items: center;
    justify-content: start;
}

.drawer-link {
    width: 125px;
    text-align: left;
    padding: 0px;
    color: rgba(0, 0, 0, 0.62);
}
