.rightTab{
    width: 50%;
    right: 0px;
    background-color: white;
    font-size: 20px;
    font-weight: bold;
    padding: 15px;
    border-radius: 0.25rem 0.25rem 0 0;
}

.leftTab{
    width: 50%;
    left: 0px;
    background-color: white;
    font-size: 20px;
    font-weight: bold;
    padding: 15px;
    border-radius: 0.25rem 0.25rem 0 0;
}

.rightTab:hover {
    cursor:pointer;
    background-color: #f6f6f7;
}

.leftTab:hover {
    cursor:pointer;
    background-color: #f6f6f7;
}

