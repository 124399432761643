.checkout-section.checkout-section-wallet-select {
    cursor: pointer;
    justify-content: space-between;
}
.checkout-order-total-value {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    padding-right: 6px;
    width: 40%;
    max-width: 150px;
    overflow: hidden;
}

.checkout-order-total-value input {
    font-family: monospace, Courier New, Courier !important;
    font-weight: bold;
    text-align: right;
    background: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #2E2F33;
    width: 100%;
}

.checkout-order-total-value input::placeholder {
    color: gray;
    font-size: 14px;
    font-family: Quicksand, monospace;
}

.checkout-order-total-value input[disabled] {
    border-bottom: none;
}

.checkout-order-total-value input:focus {
    outline: none;
}

.checkout-order-total-value input[type=number]::-webkit-inner-spin-button,
.checkout-order-total-value input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


.switch-label-left {
    color: grey;
    font-size: 15px;
    position: relative;
    bottom: 0px;
    right: 5px;
    font-weight: 500;
}

.switch-label-right {
    color: grey;
    font-size: 15px;
    position: relative;
    bottom: 0px;
    left: 5px;
    font-weight: 500;
}

.apply-moon-credit-checkbox{
    position: relative;
    cursor: pointer;
    top: 2px;
}

.apply-rewards-credit-checkbox{
    position: relative;
    cursor: pointer;
    top: 2px;
}

.apply-moon-credit-amount{
    cursor: pointer;
}

.apply-rewards-credit-amount{
    cursor: pointer;
}

.prepaid-card-tooltip {
    position: relative;
    padding-left: 5px;
}

.prepaid-card-tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: gray;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
  
    /* Position the tooltip */
    position: absolute;
    left: 25px;
    z-index: 2;
    top: -50px;
  }


  .prepaid-card-tooltip:hover .tooltiptext {
    visibility: visible;
  }