/* iPhone x - Landscape */
@media only screen
and (min-device-width : 375px)
and (max-device-width : 812px)
and (-webkit-device-pixel-ratio : 3)
and (orientation : landscape) {

    .product-content.step {
        padding-top: 0 !important;
    }

    .product-content .row:nth-child(2) {
        margin-bottom: 30px !important;
    }

}

/* iPad 3 & 4 - Portrait */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait)
and (-webkit-min-device-pixel-ratio: 2)  {
    .product-content.step {
        padding-top: 0 !important;
    }

    .product-content .row:nth-child(2) {
        margin-bottom: 30px !important;
    }

    .product-content .row:nth-child(2) > div {
        text-align: center;
    }

    .product-content .row:nth-child(2) a {
        display: block;
        font-size: 18px;
    }

}