.cards-right-tab{
    background-color: Transparent;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;
    outline:none !important;
    font-weight: 600;
}

.cards-left-tab{
    background-color: Transparent;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;
    outline:none !important;
    font-weight: 600;
}

.cards-right-tab:hover {
    cursor:pointer;
    opacity: 0.75;
}

.cards-left-tab:hover {
    cursor:pointer;
    opacity: 0.75;
}

.activeText {
    color: rgb(6, 103, 208);
    font-size: 19px;
    position: relative;
    bottom: 2px;
    right: 5px;
    font-weight: 500;
}

.inactiveText{
    color: grey;
    font-size: 19px;
    position: relative;
    bottom: 2px;
    left: 5px;
    font-weight: 500;
}