@font-face {
    font-family: 'MoonCardFont';
    src: url("./ocrb-webfont.woff") format('woff');
    font-display: block;
}

@font-face {
    font-family: 'Roboto';
    src: url("./roboto-regular-webfont.woff") format('woff');
    font-display: block;
}
