
.checkout-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    -webkit-font-smoothing: initial;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 0 2px 1px rgba(0,0,0,.2);
    box-sizing: border-box;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.checkout-section-label {
    font-size: 14px;
    font-weight: bold;
    padding: 12px 0 12px 10px;
}

.checkout-section.checkout-section-order-total {
    flex-wrap: nowrap;
}

.checkout-section-currency-flag {
    background-color: #fff;
    align-items: center;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    display: flex;
    padding: 8px 10px;
    -webkit-transition: background-color 0.2s ease;
    -moz-transition: background-color 0.2s ease;
    -ms-transition: background-color 0.2s ease;
    -o-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease;
    border-left: 1px solid #dad9e2a1;
}


.checkout-section-currency-flag.disabled {
    cursor: default;
}

.checkout-section-currency-flag:focus,
.checkout-section-currency-flag.disabled:hover {
    opacity: 1.0;

}

.checkout-section-currency-flag:hover {

}

.checkout-section-currency-flag:active {

}

.checkout-section-currency-flag img {
    height: 32px;
    padding-left: 6px;
}

.checkout-section-currency-flag div {
    color: #2e2f33;
    display: flex;
    align-items: center;
}

.checkout-payment-total-value {
    margin-left: auto;
    padding-right: 6px;
    width: 150px;
    overflow: hidden;
    margin-bottom: 2px;
}

.checkout-payment-total-value input {
    font-family: monospace, Courier New, Courier !important;
    font-weight: bold;
    text-align: right;
    background: none;
    border: none;
    width: 100%;
}

.checkout-payment-total-value input:focus {
    outline: none;
}