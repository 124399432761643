.copyright{
    font-size: .7em;
}

.web-app-footer {
    top: 100vh;
    position: sticky;
    left: 50%;
    margin: 0px !important;
    padding-bottom: 10px;
}

.social-media-icon {
    margin: 16px;
}
