.settings-container{
    display: flex;
    margin-top: 50px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 30px;
    width: 1170px;
}

.mobile-side-menu{
    display: none;
    margin-left: 25px;
    margin-right: 25px;
    width: 100%;
}

.side-menu-title{
    font-weight: bold;
    margin-bottom: 15px;
}

.side-menu{
    margin-left: 25px;
    margin-right: 50px;
}

.options{

}

.option{
    width: 250px;
    text-align: left;
    background-color: white;
    margin-bottom: 10px;
    border-radius: 0.25rem;
    border-width: 2px;
    border-color: black;
    font-weight: 700;
    color: black;
}

.option-active{
    width: 250px;
    text-align: left;
    background-color: black;
    color: white;
    margin-bottom: 10px;
    border-radius: 0.25rem;
    border-width: 2px;
    border-color: black;
    font-weight: 700;
}

.option:hover{
    background-color:#efefef;
}

.option-active:focus {
    outline: none;
}

.option:focus {
    outline: none;
}

.settings-mobile-view{
    display: none;
    width: 100%;
    margin: 0 25px;
}

@media (max-width: 970px){
    .settings-mobile-view{
        display: initial;
    }
    .settings-desktop-view{
        display: none;
    }
    .settings-container{
        display: flex;
        margin-top: 0;
        margin-right: 0;
        margin-left: 0;
        padding-left: 0;
        width: 100%;
    }
    .mobile-side-menu{
        display: initial;;
    }
    .side-menu{
        display: none;
    }
    .option-active{
        width: 100%;
        padding: 10px 5px;
    }
    .option{
        width: 100%;
        padding: 10px 5px;
    }
}