.payment-method-selector-container {
    user-select: none;
}

.payment-method-selector-container-shown{
    animation: menuSlideOut .5s 1;
}

.gray-text{
    font-style: italic;
    font-size: 12px;
    color: gray;
    text-align: right;
}

.checkout-wallet-select-selected-wallet {
    cursor: pointer;
    margin-left: auto;
    text-align: right;
    padding: 12px 6px 12px 0;
}

.checkout-wallet-select-selected-wallet-name {
    font-size: 16px;
    font-weight: bold;
}

.checkout-wallet-select-change-wallet-text-area {
    white-space: nowrap;
}

.checkout-wallet-select-selected-wallet-balance {
    font-family: monospace,Courier New,Courier!important;
    font-size: 80%;
}

.checkout-wallet-select-selected-wallet-balance.link {
    z-index: 20000 !important;
    font-size: 12px !important;
}

.checkout-wallet-select-selected-wallet-balance-base {
    opacity: 0.75;
    font-family: monospace,Courier New,Courier!important;
}

.checkout-wallet-select-change {
    cursor: pointer;
    min-height: 52px;
    align-items: center;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    display: flex;
    padding: 12px 10px;
    -webkit-transition: background-color 0.2s ease;
    -moz-transition: background-color 0.2s ease;
    -ms-transition: background-color 0.2s ease;
    -o-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease;
}

.checkout-wallet-select-change.inverse svg {
    transform: rotate(-180deg);
}

.checkout-wallet-select-change svg {
    height: 16px;
    -webkit-transition: transform 0.2s ease;
    -moz-transition: transform 0.2s ease;
    -ms-transition: transform 0.2s ease;
    -o-transition: transform 0.2s ease;
    transition: transform 0.2s ease;
}

.checkout-wallet-select-change path {
    color: #0F62BD;
}

.checkout-wallet-select-change-wallet-selector, .checkout-wallet-select-change-options-selector {
    position: absolute;
    width: 100%;
    box-shadow: inset 0 0 5px 0px rgba(0,0,0,.2);
    box-sizing: border-box;
    background-color: white;
    -webkit-appearance: none;
    max-height: 125px;
    overflow-y: auto;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    top: var(--desktop-bottom);
    z-index: 3;
}

.checkout-wallet-select-change-options-selector {
    z-index: 1;
    overflow: visible;
    min-height: 70px;
}

.checkout-wallet-select-change-wallet-selector.collapsed, .checkout-wallet-select-change-options-selector.collapsed {
    max-height: 0;
    opacity: 0;
}

.checkout-wallet-select-change-wallet-selection {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 5px 0;
    cursor: pointer;
    text-align: left;
    -webkit-transition: background 0.2s ease;
    -moz-transition: background 0.2s ease;
    -ms-transition: background 0.2s ease;
    -o-transition: background 0.2s ease;
    transition: background 0.2s ease;
    user-select: none;
}

.checkout-wallet-select-change-wallet-selection .country-flag {
    padding-left: 10px;
}

.checkout-wallet-select-change-wallet-selection.COINBASE:hover {
    background: #0667D0;
}

.checkout-wallet-select-change-wallet-selection:hover {
    background: #2E2F33;
}

.checkout-wallet-select-change-wallet-selection * {
    -webkit-transition: color 0.2s ease;
    -moz-transition: color 0.2s ease;
    -ms-transition: color 0.2s ease;
    -o-transition: color 0.2s ease;
    transition: color 0.2s ease;
}

.checkout-wallet-select-change-wallet-selection:hover * {
    color: white;
}

.checkout-wallet-select-change-wallet-selection-value * {
    font-family: monospace,Courier New,Courier!important;
}

.checkout-wallet-select-change-wallet-selection-value.my-0.font-size-80 {
    position:relative;
    top: -4px;
}

.checkout-wallet-select-change-wallet-selection-fiat-value {
    width:100%;
}

.checkout-wallet-select-change-wallet-selection-fiat-value p {
    margin: 0;
    font-family: monospace,Courier New,Courier!important;
    padding-top: 5px;
    padding-right: 15px;
    font-size: 16px;
    text-align: right;
    opacity: .75;
}

input[disabled], textarea[disabled],
select[disabled='disabled']{
   -webkit-text-fill-color: rgba(0, 0, 0, 1); 
   -webkit-opacity: 1; 
   color: rgb(0, 0, 0);
   background: white;
}

.checkout-payment-button {
    text-align: center;
}

.btn.btn-pay {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: calc(100% - 42px);
}

.discount-green-text {
    color: #287412;
    font-weight: 600 !important;
}

.CONNECT_COINBASE {
    border: 1.5px solid #0667D0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding-top: 0;
    padding-bottom: 0;
    height: 43px;
}

.CONNECT_COINBASE:hover {
    background-color: #0667D0;
    color: #fff;
    opacity: 0.8;
}

.CONNECT_COINBASE .btn-coinbase-icon {
    background-color: #0667D0;
}

.CONNECT_COINBASE .btn-coinbase-icon svg {
    width: 20px;
}

.btn-coinbase-icon svg path {
    fill: #fff;
}

.you-earned{
    text-align: right !important;
    text-blink: true;
    font-weight: 900 !important;
    color: #007600 !important;
    padding-top: 12px;
    padding-bottom: 12px;
}

.no-highlight {
    user-select: none;
}

ol.sequence {
    border-color: #2E2F33;
    counter-reset: li;
    font-size: 14px;
    margin-bottom: 0;
    margin-left: 25px;
    margin-top: 0;
    padding-left: 24px;
    position: relative;
}

ol.sequence:empty {
    padding: 8px 0;
}

ol.sequence li {
    list-style: none;
    position:relative;
}

ol.sequence li:first-child {
    /*padding-top: 12px;*/
}

ol.sequence li::before {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background-color: #2E2F33;
    border-radius: 50%;
    box-sizing: border-box;
    color: #fff;
    content: counter(li);
    counter-increment: li;
    display: block;
    font-weight: 700;
    font-size: 15px;
    height: 28px;
    left: -33px;
    margin-top: -4px;
    outline: 0;
    padding: 4px;
    position: absolute;
    text-align: center;
    width: 28px;
    z-index: 2;
}

ol.sequence li::after {
    background: #2E2F33 0 0;
    background-size: 200% 200%;
    content: "";
    height: calc(95% - 38px);
    left: -20px;
    outline: 0;
    position: absolute;
    top: 31px;
    width: 2px;
}

ol.sequence li.current {
    color: #0f62bd;
    font-weight: bold;
    text-transform: uppercase;
}

ol.sequence li.current::before {
    content: "\279C";
    background-color: #0f62bd !important;
}

ol.sequence li.current::after {
    background: #0f62bd 0 0 !important;
}

ol.sequence li.done {
    color: #23821D;
}

ol.sequence li.done::before {
    content: "\2713";
    background-color: #23821D !important;
}

ol.sequence li.done::after {
    background: #23821D 0 0 !important;
}

ol.sequence li:last-child::after {
    display: none;
}

ul.sequence {
    border-color: #5f6066;
    font-size: 14px;
    margin-bottom: 0;
    margin-left: 25px;
    margin-top: 0;
    padding-left: 24px;
    position: relative;
}

ul.sequence:empty {
    padding: 8px 0;
}

ul.sequence:empty::before {
    background: #5f6066 0 0;
    background-size: 200% 200%;
    border: 0 solid inherit;
    content: "";
    height: 100%;
    outline: 0;
    position: absolute;
    width: 2px;
    left: 5px;
    top: 0;
}

ul.sequence li {
    list-style: none;
}

ul.sequence li:first-child {
    padding-top: 12px;
}

ul.sequence li::before {
    border: 2px solid #5f6066;
    border-radius: 50%;
    background-color: #5f6066;
    content: "";
    display: block;
    height: 10px;
    outline: 0;
    position: absolute;
    width: 10px;
    z-index: 2;
    left: 1px;
    bottom: var(--dot-bottom);
}

ul.sequence li::after {
    background: #5f6066 0 0;
    background-size: 200% 200%;
    border: 0 solid inherit;
    content: "";
    height: 100%;
    outline: 0;
    position: absolute;
    width: 2px;
    left: 5px;
    top: 0;
}

ul.sequence li:last-child {
    padding-bottom: 12px;
}

.payment-method-selector-container ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
.payment-method-selector-container ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

@media (max-width: 1080px) {
    .checkout-wallet-select-change-wallet-selector, .checkout-wallet-select-change-options-selector {
        top: var(--mobile-bottom)
    }
}

@media (max-width: 499px){
    .checkout-wallet-select-change-wallet-selector {
        top: 505px; 
    }
    .checkout-wallet-select-change-options-selector {
        top: 543px; 
    }
}