.connect-to-coinbase-title{
    font-weight: bold;
    margin-bottom: 15px;
}

.btn-icon{
    padding: 10px;
}

.btn-connect-coinbase  .btn-icon {
    background-color: #0667D0;
    width: 40px;
    height: 40px;
    position: absolute;
    transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
}

.btn-connect-coinbase .btn-icon path {
    color: white !important;
}

.btn-connect-coinbase  .btn-icon svg {
    height: 100%;
}

.btn-connect-coinbase  {
    padding: 0;
    border: 2px solid #0667D0;
    color: black;
    background-color: white;
    border-radius: 3px;
    margin-bottom: 50px;
    width: 300px;
}

.btn-connect-coinbase  * {
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}


.btn-connect-coinbase  .btn-text {
    box-sizing: border-box;
    display: block;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .7px;
    line-height: 20px;
    overflow: hidden;
    padding: 10px 15px 10px 54px;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
    width: 100%;
    text-align: center;
}

.btn-connect-coinbase :hover {
    background-color: #0667D0;
    border-color: #0667D0;
    color:white;
}

.btn-connect-coinbase :active, .btn-add-card:focus {
    background-color: #0667D0;
    border-color: #0667D0;
    opacity: 0.6;
}

.btn-connect-coinbase :hover .btn-text, .btn-connect-coinbase :active .btn-text, .btn-connect-coinbase :focus .btn-text {
    color: white;
    background-color: #0667D0;
    border-color: #0667D0;
}

.checkmark {
    font-size: 48px;
}

.checkmark path {
    fill: #19692c !important;
}

.coinbase-connect-text{
    position: relative;
    font-weight: 500;
    top: 12px;
    left: 15px;
}

.coinbase-connected{
    display: flex;
}

.connect-to-coinbase-back-btn-icon{
    display: none;
}

@media (max-width: 970px){
    .connect-to-coinbase-back-btn-icon{
        display: initial;
    }
    .connect-to-coinbase-title{
        margin-top: 15px;
    }
    .btn-connect-coinbase{
        width: 100%
    }
}