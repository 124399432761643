.refund-section{
    display: flex;
    margin-top: 20px;
    width: 600px;
}

.refund-left-section{
    display: grid;
    text-align: left;
    width: 225px;
}

.refund-middle-section{
}

.refund-right-section{
    margin-left: auto;
}

.refund-history-status-pending{
    font-weight: 500;
    color: #F5BC42;
}

.history-title-amount{
    font-size: 18px;
}

.history-title-destination{
    font-size: 18px;

}

.history-title-status{
    font-size: 18px;
    position: relative;
    right: 18px;
}

.refund-history-status-sent{
    font-weight: 500;
    text-decoration: underline;
    color: #008000;
    cursor: pointer;
}

.refund-history-status-sent:hover{
    opacity: 0.7 !important;
}

.refund-history-status-sent:active{
    opacity: 1 !important;
}

.refund-history-address{
    cursor: pointer;
}

.refund-history-address:hover{
    opacity: 0.5 !important;
}

.refund-title{
    font-size: 22px;
    font-weight: bold;
    position: relative;
    top: 8px;
    margin-right: 15px;
    width: fit-content;
    margin-bottom: 28px;
}

.refund-title-underline{
    width: 600px;
    border-top: 0.5px solid #7B7B7B;
}

@media (max-width: 970px) {
    .refund-title-underline{
        width: auto;
    }    
    .refund-middle-section{
        position: absolute;
        right: 40%;
    }
    .refund-section{
        width: auto;
    }
    .history-title-destiniation-text{
        left: -8px;
        position: relative;
    }
}

@media (max-width: 500px) {
    .refund-date{
        width: 100px;
    }
    .refund-middle-section{
        right: 37%;
    }
    .refund-date{
        width: 80px;
    }
}