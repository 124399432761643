.moon-credit-title{
    font-weight: bold;
    margin-bottom: 15px;
}
.mobile-moon-credit-view{
    display: none;
}
.moon-credit-element-title{
    font-size: 22px;
    font-weight: bold;
    position: relative;
    top: 8px;
    margin-right: 15px;
    width: fit-content;
}
.credit{
    color: #2f4ac2;
    font-size: 22px;
    font-weight: 500;
    position: relative;
    left: 30px;
    bottom: -3px;
}

.credit-section{
    display: flex;
    margin-bottom: 20px;
}
.credit-definition{
    width: 55%;
    margin-bottom: 30px;
}
.refund-request-btn{
    width: 55%;
}

@media (max-width: 970px){
    .moon-credit-element-title{
        top: 0;
        margin-right: 0;
        margin-bottom: 8px;
    }
    .moon-credit-title{
        margin-top: 15px;
    }
    .desktop-moon-credit-view{
        display: none;
    }
    .mobile-moon-credit-view{
        display: initial;
    }
    .credit{
        left: 0;
        bottom: -4px;
    }
    .credit-section{
        text-align: -webkit-center;
        display: block;
    }
    .credit-definition{
        width: 100%;
        margin-top: 30px;
    }
    .refund-request-btn{
        width: 100%;
    }
}

@media (max-width: 500px){
    .credit{
        font-size: 32px;
    }
}