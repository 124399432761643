.CardBoxActive {
    animation: active-loader 0.8s;
    box-shadow: 0 5px 10px rgb(154 154 154);
    cursor: pointer;
}

.CardBoxInactive {
  animation: inactive-loader 0.8s;
  box-shadow: 0 5px 10px rgb(154 154 154);
  cursor: pointer;
  opacity: 0.5;
}

.CardBoxActive:hover {
  transform: scale(1.02);
}

.CardBoxInactive:hover {
  transform: scale(1.02);
}

.noHover {
  transform: scale(1) !important;
  cursor: initial;
}

.hoverLink:hover {
  outline: 1px solid #007BFF;
  cursor: pointer;
}

@keyframes active-loader {
    from {
      transform: translateX(-100%);
      transition-timing-function: ease-in;
    }
    to {
      transform: translateX(0px);
      transition-timing-function: ease-out;
    }
}

@keyframes inactive-loader {
  from {
    transform: translateX(100%);
    transition-timing-function: ease-in;
  }
  to {
    transform: translateX(0px);
    transition-timing-function: ease-out;
  }
}

@media (max-width: 1093px){
  .CardBoxInactive{
      animation: none;
  }
  .CardBoxActive{
    animation: none;
  }
}