.ui-bitcoin-payment-method-container {
}

.hide{
  display: none;
}

.wallet-address {
    display: flex;
    text-align-last: center;
  }
  .wallet-address:active {
    opacity: 0.5;
  }

.bitcoin-payment-method-qr-code-canvas-onchain {
    animation: bitcoin-payment-active-loader-onchain 0.8s;
  }

  .bitcoin-payment-method-address-onchain {
    animation: bitcoin-payment-active-loader-onchain 0.8s;
  }

  .bitcoin-payment-method-qr-code-canvas-lightning {
    animation: bitcoin-payment-active-loader-lightning 0.8s;
  }

  .bitcoin-payment-method-address-lightning {
    animation: bitcoin-payment-active-loader-lightning 0.8s;
  }


  .bitcoin-payment-method-order-total-value {
    width: 73%;
  }

  .bitcoin-payment-method-order-total-value span {
    font-size: 12px;
    font-family: monospace, 'Courier New', 'Courier' !important;
    text-align: right;
    display: inline-block;
    border: 0;
    width: 100%;
    color: black;
  }

  .bitcoin-payment-method-address {
    text-align-last: center;
  }
  .bitcoin-payment-method-order-total-value:active {
    opacity: 0.5;
  }

  .bitcoin-payment-method-content-container {
    display: block;
    justify-content: center;
    text-align: center;
  }
  
  .bitcoin-payment-method-content-container .cancel {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.8px;
    cursor: pointer;
    text-decoration: underline;
  }

  .bitcoin-payment-method-content {
    display: inline-block;
    padding: 0 16px;
  }
  
  .bitcoin-payment-method-content span {
    font-size: 12px;
    letter-spacing: 0.5px;
    font-weight: 500;
  }
  
  .bitcoin-payment-method-content h3 {
    margin: 0;
    font-weight: 700;
    font-family: monospace, 'Courier New', 'Courier' !important;
    text-align: center;
    font-size: 32px;
  }

  .bitcoin-payment-method-open-wallet-btn-onchain {
    animation: bitcoin-payment-active-loader-onchain 0.8s;
  }

  .bitcoin-payment-method-open-wallet-btn-lightning {
    animation: bitcoin-payment-active-loader-lightning 0.8s;
  }

  @keyframes bitcoin-payment-active-loader-onchain {
    from {
      transform: translateX(-5%);
      transition-timing-function: ease-in;
    }
    to {
      transform: translateX(0px);
      transition-timing-function: ease-out;
    }
  }

  @keyframes bitcoin-payment-active-loader-lightning {
    from {
      transform: translateX(5%);
      transition-timing-function: ease-in;
    }
    to {
      transform: translateX(0px);
      transition-timing-function: ease-out;
    }
  }

  @media (max-width: 1080px) {
    .cancel {
      display: none;
    }
    .ui-bitcoin-payment-method-container {
      display: grid;
      flex-direction: column;
    }
    .mobile-view {
      display: initial;
    }
    .ui-bitcoin-payment-method-loading-container {
      width: 100%;
      bottom: 0;
      position: initial;
      right: 0;
      margin-top: 100px;
    }
    .header {
      margin-top: 20px;
      order: 1;
      max-width: 500px;
      place-self: center;
    }
  }