.agreement-title{
    text-align: center;
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}

.agreement-body{
    max-width: 700px;
    height: 300px;
    overflow: auto;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid black;
    padding: 5px 10px 10px 10px;
    border-radius: 0.25rem;
}

.agreement-button{
    max-width: 215px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem !important;
}

.error{
    color: red;
    text-align: center;
    margin-top: 15px;
}

@media (max-width: 750px) {
    .agreement-body{
        margin-left: 25px;
        margin-right: 25px;
    }
}