.bitcoin-payment-right-tab {
  width: 50%;
  right: 0px;
  background-color: white;
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  border-radius: 0 1rem 1rem 0;
}

.bitcoin-payment-left-tab {
  width: 50%;
  left: 0px;
  background-color: white;
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  border-radius: 1rem 0 0 1rem;
}

.bitcoin-payment-right-tab:hover {
  cursor: pointer;
  background-color: #f6f6f7;
}

.bitcoin-payment-left-tab:hover {
  cursor: pointer;
  background-color: #f6f6f7;
}

.bitcoin-payment-tabs-desktop {
  display: flex;
}

.bitcoin-payment-tabs-mobile {
  display: none;
}

@media (max-width: 1100px) {
  .bitcoin-payment-tabs-desktop {
    display: none;
  }
  .bitcoin-payment-tabs-mobile {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
  }
}
