.card-product-logo {
    max-height: 130px;

    @media (max-width: 500px) {
        max-height: 107px;
    }
}

.infinite-scroll-component__outerdiv {
    height: 100%;
}
.card-product-container {
    width: 200px;
    height: 125px;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @media (max-width: 500px) {
        width: 167px;
        height: 107px;
    }
}

.card-product-container:hover {
    transform: scale(1.02);
}

.card-products-table{
    border-spacing: 20px;
    border-collapse: separate;
}

.card-products-container {
    margin-top: 15px;
    margin-bottom: 25px;
}

.page-selector{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    text-align: center;

    @media (max-width: 500px) {
        position: relative;
    }
}

@media (max-width: 500px) {
    .card-product-container {
        width: 167px;
        height: 107px;
    }
    .page-selector{
        position: relative;
    }
    .card-product-logo{
        max-height: 107px;
    }
}