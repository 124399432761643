.profile-title{
    font-weight: bold;
    margin-bottom: 15px;
}

.rewards-section{
    display: flex;
    margin-bottom: 20px;
}

.name-section{
    display: flex;
    margin-bottom: 10px;
}

.email-section{
    display: flex;
}

.element-title{
    font-size: 22px;
    font-weight: bold;
    position: relative;
    top: 8px;
    margin-right: 15px;
    width: 70px;
}

.rewards-sats{
    color: #47933e;
    font-size: 22px;
    font-weight: 500;
    position: relative;
    left: 30px;
    bottom: 3px;
}

.profile-info{
    display: flex;
}

.moon-logo{
    width: 150px;
    height: 100%;
    margin-right: 25px;
}

.profile-name-email{
    display: block;
}

.bitcoin-payment-method-dropdown {
    margin-top: 5px;
    height: 35px;
    color: black;
    background-color: white;
    border-color: black;
    border-radius: .25rem;
}

.line{
    background-color: black;
    height: 1px;
    width: 255px;
}

.name-text{
    color: #656565;
    font-size: 22px;
    border: none;
    text-align: center;
}

.green-text{
    text-align: center;
    color: green;
    position: relative;
}

.name-text:focus{
    outline:none!important;
}

.email-text{
    color: #8F8F8F;
    overflow-wrap: break-word;
}

.email-container{
    text-align: center;
    position: relative;
    top: 8px;
}

.mobile-profile-view{
    display: none;
}

.cardLimit-section{
    text-align: center;
}

.progress{
    width: 100%;
    height:15px;
    border:1px solid #2e2f33;
    position:relative;
    margin: 5px 40px 0 40px;
    margin-left: auto;
    background-color: white;
    border-radius: .25rem;
}

.progress:after {
    content:'\A';
    position:absolute;
    background:#2e2f33;
    top:0; bottom:0;
    left:0; 
    width: var(--width);
}

.default-bitcoin-payment-method-divider {
    margin-top: 10px;
}
@media (max-width: 970px){
    .moon-logo{
        height: auto;
    }
    .profile-title{
        margin-top: 15px;
    }
    .element-title{
        position: initial;
        margin-right: 0;
        width: auto;
    }
    .name-section{
        display: block;
    }
    .email-section{
        display: block;
    }
    .moon-logo{
        margin-right: 0px;
        margin-bottom: 15px;
    }
    .profile-info{
        display: block;
        text-align: center;
    }
    .rewards-sats{
        left: 0;
    }
    .rewards-section{
        text-align: -webkit-center;
        display: block;
    }
    .default-bitcoin-payment-text {
        font-size: 18px;
        margin-bottom: 0px;
    }
    .default-bitcoin-payment-method-divider {
        margin-top: 0px
    }
    .desktop-profile-view{
        display: none;
    }
    .mobile-profile-view{
        display: initial;
    }
    .settings-container{
        justify-content: center;
    }
    .line{
        width: 100%;
    }
    .option{
        text-align: center;
    }
}