.Cards.loading-card {
    margin-top:20px;
    background-color: #FFFFFF;
    animation: none;
    text-align: left;
}

.loading-virtual-card{
    position:absolute;
    top: 17px;
    right: 25px;
    font-size: 10px;
    color: gray;
}

.loading-amount {
    width: 30%;
    height: 50px;
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    border-radius: 3px;
    font-family: 'MoonCardFont', monospace !important;
    color: #F6F7F8;
}

.loading-pan {
    width: 80%;
    height: 20px;
    position: relative;
    margin-top: 20px;
    margin-left: 6%;
    border-raidius: 3px;
    font-family: "Roboto", serif !important;
    color: #F6F7F8;
}

.exp-cvv-cont{
    width: 80%;
    height: 20px;
    position:relative;
    margin-top: 20px;
    margin-left: 6%;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
}

.loading-exp{
    width: 40%;
    height: 20px;
}

.loading-cvv{
    width: 40%;
    height: 20px;
}
